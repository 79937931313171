div.Serive_div {
  padding: none;
  margin: none;
}

body {
  padding: 0px;
  margin: 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 199px;
  padding-left: 0px;
  padding-right: 0px;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.queResponse {
  padding: 5px;
  width: 406px;
  margin: auto;
  border: 1px solid gray;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.custom-textarea::placeholder {
  margin-left: 0px;
  padding-left: 0px;
}

.searchBtn {
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.dark-mode .searchBtn{
  background-color: black;
}

/* Default style for my-button class */
.my-button {
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

/* Dark mode styling for my-button class */
.dark-mode .my-button {
  background-color: black;
  color: white;
}

/* Style for active state within my-button class */
.my-button.active {
  background-color: #3d83fa;
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  height: 36px;
}

/* Dark mode active button styling within my-button class */
.dark-mode .my-button.active {
  background-color: #3d83fa;
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  height: 36px;
}

.dark-mode .darkSearchInput {
  background-color: black;
  color: white;
  border: 1px solid white;
}


.Switch_css {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 95%;
}

.Input_div {
  width: 90%;
  margin: auto;
}

.Suggetion_div {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 88%;
  margin-top: 20px;
}

.Suggetion_div div {
  width: 30px;
}

.Service_bottom {
  width: 95%;
  margin: auto;
  margin-top: 5px;
}

.Service_bottom div {
  padding-top: 5px;
}

.Suggestion_tag_div {
  padding: 5px;
  width: 406px;
  margin: auto;
  display: flex;
  border: 1px solid gray;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
}

.Ai_Review_div {
  padding: 5px;
  width: 410px;

  margin: 15px;
  border-bottom: 2px solid rgb(50, 50, 50);
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3); */
  justify-content: space-between;
}

.coauthor-button {
  border: none;
  outline: none;
  font-size: 12px;
  cursor: pointer;
  /* background-color: white; */
  /* border-radius: 20px; */
  transition: background-color 0.3s ease;
  margin-right: 5px;
  /* Adjust margin between buttons */
}

/* Style for active button */
.coauthor-button.active {
  color: #3d83fa;
  border: none;
  /* border-radius: 20px; */
}

.dark-mode .bgGray{
  background-color: #2a2a2a;
}

.dark-mode .blackBorder{
  border: 1px solid black;
}


/* Citation css */
.custom-form-group-width {
  width: 85%;
  margin: auto;
}

.custom-form-group-width-date {
  width: 85%;
  margin: auto;
}


/* Sidebar css */
.citation_Hover,
.aireview_Hover,
.notes_Hover,
.search_Hover {
  cursor: pointer;
}

/* .search_Hover:hover {
  background-color: #3d83fa;
  color: white;
  border-radius: 35px 35px 0px 0px ;
} */
.search_Hover:hover div {
  background-color: #3d83fa;
  color: white;
  border-radius: 35px 35px 0px 0px;
}

.search_Hover:hover div h6 {
  color: white;
}

.search_Hover:hover div .MuiSvgIcon-root {
  color: white;
  /* Target the SVG icon directly */
}

.notes_Hover:hover div {
  background-color: #3d83fa;
  color: white;
}

.notes_Hover:hover div h6 {
  color: white;
}

.notes_Hover:hover div .MuiSvgIcon-root {
  color: white;
}

.citation_Hover:hover div {
  background-color: #3d83fa;
  color: white;
}

.citation_Hover:hover div h6 {
  color: white;
}

.citation_Hover:hover div .MuiSvgIcon-root {
  color: white;
}

/* .citation_Hover:hover {
  background-color: #3d83fa;
  color: white;
} */
/* .aireview_Hover:hover {
  background-color: #3d83fa;
  color: white;
} */
.aireview_Hover:hover div {
  background-color: #3d83fa;
  color: white;
}

.aireview_Hover:hover div h6 {
  color: white;
}

.aireview_Hover:hover div .MuiSvgIcon-root {
  color: white;
}

.plagiarism_Hover {
  color: black;
}

.plagiarism_Hover:hover div {
  background-color: #3d83fa;
  color: white;
  border-radius: 0px 0px 35px 35px;
}

.plagiarism_Hover:hover div h6 {
  color: white;
}

.plagiarism_Hover:hover div .MuiSvgIcon-root {
  color: white;
}

.close_coauthormodal {
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.close_coauthormodal:hover {
  background-color: #e32428;
  color: white;
  /* transition: 0.2s ease-in-out; */
  border-radius: 6px;

}

.whiteText{
  color: black;
}

.dark-mode  .whiteText{
  color: white;
}

.dark-mode .MuiFormLabel-root {
  color: white;
}

.dark-mode .MuiInputLabel-root{
  color: white;
}

.plagiarism_Hover:hover div,
.notes_Hover:hover div,
.citation_Hover:hover div,
.aireview_Hover:hover div,
.search_Hover:hover div {
  color: white;
}

.changeBG {
  background-color: white !important;
}

.dark-mode .changeBG {
  background-color: #2a2a2a !important;
}

.blackBg {
  background-color: white !important;
}

.dark-mode .blackBg {
  background-color: black !important;
}

.blackBgInput{
  background-color: white !important;
  color: #495057 !important;
}
.dark-mode .blackBgInput{
  background-color: black !important;
  color: white !important;
}

@media only screen and (max-width: 770px) {

  .plagiarism_Hover:hover div,
  .notes_Hover:hover div,
  .citation_Hover:hover div,
  .aireview_Hover:hover div,
  .search_Hover:hover div {
    border-radius: 15px;
  }

}