


.Maindiv1{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 60px;
}
.headtag1{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
 
}
.headtag_flex1{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    align-items: center;
    margin-top: 15px;
}
.headtag_inner_div1{
    font-size: 15px;
    font-weight: 600;
}
 
.top_button_style1{
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
}
.top_button_style1 Button{
    border: 1px solid gray;
    width: 150px;
    cursor: pointer;
    margin-bottom: 20px;
}
.editorDiv1{
    margin-top:"40px";
    width: 95%;
    border-radius: "8px 8px 0px 0px";
    background: "white";
    min-height: 300px;
    max-height: 300px;

    overflow: scroll;
    box-shadow:"0 0px 0px rgba(0, 0, 0, 0.1" ;
}
 
div .bottomButtons1{
    display: flex;
    margin-top: 100px;
    justify-content: "space-between";
    gap: 5px;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    background: "white";
    min-height: 70px;
    max-height: 70px;
}
 