/* modalStyles.css */

/* .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    background-color: white;
    border: 1px solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    padding: 20px;
} */


.button {
    background-color: white;
    border: 1px solid #000;
    padding: 10px 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s, border-radius 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15%;
    gap: 10px;
}

.button:hover {
    background-color: skyblue !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
