.bgCardColor {
    background-color: white !important;
}

.dark-mode .bgCardColor {
    background-color: #2a2a2a !important;
    border: #2a2a2a !important;
}
.cardStyle {
    border: 1px solid white !important;
    box-shadow: 3px 1px 8px 0px rgba(0,0,0,0.25) !important;
}

.dark-mode .cardStyle {
    border: 1px solid black !important;
    box-shadow: none !important;
}

.dark-mode .MuiCard-root {
    background-color: #2a2a2a !important;
}

.MoreVertIcon{
    color: blue ;
}

.dark-mode .MoreVertIcon{
    color: white !important;
}
.dark-mode .innerCardBorder{
    border: 2px solid transparent !important;
    border-top: 2px solid gray !important;

}

.dark-mode .MuiPaper-root{
    color: white;
}

.dateLabelcolor{
    color: black !important;
}

.dark-mode .dateLabelcolor{
    color: white !important;
}

/* Styles for the menu in dark mode */
.dark-mode .MuiMenu-root {
    background-color: #333; /* Dark gray background */
    color: white !important; /* Default text color */
}

/* Styles for individual menu items */
.dark-mode .MuiMenuItem-root {
    background-color: transparent; /* Transparent background for menu items */
    color: black !important; /* Text color */
}

/* Optional: Change background color on hover */
.dark-mode .MuiMenuItem-root:hover {
    /* background-color: #6e6969; -----------not working bg color, when appply text not display */
    color: black !important;
    border: 1px solid black;
}

/* Optional: Style for disabled menu items */
.dark-mode .MuiMenuItem-root.Mui-disabled {
    color: #aaa; /* Lighter gray for disabled items */
}


