.Left-side-coauthor{
    background-color: white;
}

.dark-mode .Left-side-coauthor {
    background-color: #2a2a2a;
}

.topMenuColorChange{
    color: black;
}

.dark-mode .topMenuColorChange{
    color: lightgray;
}

.dark-mode .topMenuColorChange:disabled{
    color: rgb(145, 142, 142);
}

.dark-mode .grayBorderbottom{
    border-bottom: 3px solid lightgray;
}

/* Popover styling for dark mode */
.dark-mode .MuiPopover-root {
    background-color: transparent !important; /* Dark background for the Popover */
    color: white !important; /* Ensures text is white */
}

/* Styling for the menu items in the Popover */
.dark-mode .MuiMenuItem-root {
    color: white !important; /* White text for the menu items */
    background-color: #444 !important; /* Darker background for items */
}

/* Menu item hover effect */
.dark-mode .MuiMenuItem-root:hover {
    background-color: rgb(220, 217, 217) !important; /* Lighter background on hover */
}

/* Optional: Disabled menu item styling */
.dark-mode .MuiMenuItem-root.Mui-disabled {
    color: #aaa !important; /* Light gray for disabled items */
}

.dark-mode .MuiMenuItem-root.Mui-selected {
    color: black !important; /* Black text for the selected item */
}

.dark-mode .OutlinedDarkBtn {
    border-color: white !important; /* Ensures the border is white */
    color: white !important; /* Ensures the text is white */
  }

  .dark-mode .whiteHeading{
    color: white;
  }