@media only screen and (max-width: 600px) {
  #send {
    /* margin-left: 10% !important; */
  }
}

#soflow-color {
  /* color: #fff;
  background-color: #84c7f4; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding-left: 15px;
}
