.MuiCard-root {
  background-color: white !important;
}

.whiteText{
  color: black;
}

.dark-mode  .whiteText{
  color: white;
}

.docName{
  color: black;
}

.dark-mode  .docName{
  color: #007bff;
}

.changeBggray {
  background-color: #fff;
}

.dark-mode .changeBggray {
  background-color: #2a2a2a;
}

.themeBtn{
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
  transition: color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.themeBtn:hover {
  color: white;
  background-color: #007bff;
  border: 1px solid #007bff;
}

.dark-mode .themeBtn{
  color: white;
  border: 1px solid white;
}

.acceptColor{
  color: green !important;
}

.dark-mode .acceptColor{
  color: #00FF1A !important;
}

.rejectColor{
  color: red !important;
}

.dark-mode .rejectColor{
  color: #FF0004 !important;
}

.bothColor{
  color: #3f51b5 !important;
}

.dark-mode .bothColor{
  color: #0AC6FF !important;
}