.cite-button {
    display: inline-block;
    padding: 8px 12px;
    text-decoration: none;
    /* color: #ffffff; */
    /* background-color: #1976d2; */
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .cite-button-sx{
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  .cite-button:hover {
    background-color: #bdbdbd;
  }
 
  .plus-icon {
    /* margin-right: 5px; */
    justify-content: right;
  }

  .plus-icon-sx{
    display: flex;
    text-align: center;
    justify-content: center;
    border:1px solid #0069d9;
    border-radius: 10px;
    margin-left: 4%;
  }

  .cite{
    /* box-shadow: 3px 1px 8px 0px rgba(0,0,0,0.25); */
    background-color:#FFFFFF ;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding-top: 1%;
    padding-left: 2%;
    padding-bottom: 1%;
  }

  .cite-sx{
    background-color:#FFFFFF ;
    border-radius: 15px;
    padding-top: 1%;
    padding-left: 2%;
    padding-bottom: 1%;
  }

  .cite-right{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color:#FFFFFF ;
    padding-top: 1%;
    padding-left: 2%;
    padding-bottom: 1%;
  }

  #hoverchange{
    display: flex;
    align-items: center;
    justify-content: center;
    height:35px;
    width:10%;
    padding:5px;
  }

  #hoverChange:hover{
    background-color: lightgray;
   border-radius: 50%;
    transition:  0.3s; 
  }


  .dark-mode .cite{
    background-color: #323232;
}

.dark-mode .cite-sx{
  background-color: #323232;
}

.dark-mode .cite-right{
  background-color: #323232;
}

.dark-mode .search-icon{
  color: white;
}

.search-bar{
  background-color: white;
}

.dark-mode .search-bar{
  background-color: black;
  border: 1px solid white
}

.dark-mode .MuiTabs-flexContainer {
  background-color: #323232 !important
}

.dark-mode .MuiTab-wrapper {
  color: #ffffff !important
}

.nav-tabs{
  background-color: white;
}

.dark-mode .nav-tabs{
  background-color: #323232;
}

.diabledInDarkMode:disabled {
  background-color: lightgray !important; 
  color: gray !important; 
  border-color: lightgray !important; 
  /* cursor: not-allowed; */
}

.dark-mode .diabledInDarkMode:disabled {
  background-color: lightgray !important; 
  color: gray !important; 
  border-color: lightgray !important; 
  /* cursor: not-allowed; */
}