/* .inputBoxStyle {
    color: black;
} */

.dark-mode .inputBoxStyle {
    color: white;
    background-color: black;
}

.dark-mode .textColor{
    color: white;
}