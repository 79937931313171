li {
  margin-bottom: 0.5em;
}

div.footnote {
  text-indent: -1.3em;
  margin-left: 1.3em;
  margin-bottom: 0.2em;
}


div.pagebreak {
  page-break-before: always;
}

.hangindent {
  text-indent: -2em;
  margin-left: 2em;
}

h2 {
  font-size: 110%;
  margin-top: 1em;
  margin-bottom: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
}

h3 {
  font-size: 95%;
  margin-top: 0.9em;
  margin-bottom: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
}

div.csl-left-margin {
  width:2em;
  float:left;
  text-indent:1em;
  padding:0.4em 0px 0.4em 0em;
}

div.dateindent div.csl-left-margin {
  width:3em;
  float:left;
  text-indent:1em;
  padding:0.4em 0px 0.4em 0em;
}

div.dateindent div.csl-block {
  font-weight: bold;
}

.csl-right-inline {
  margin-left:2em;
  padding:0.4em 0.4em 0.4em 1em;
}

.dateindent div.csl-right-inline {
  margin-left:3em;
  padding:0.4em 0.4em 0.4em 1em;
}

.csl-indent {
  margin-top: 0.5em;
  margin-left: 2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  border-left: 5px solid #ccc;
}

.heading {
  border-top: 5px solid #ccc;
  border-bottom: 5px solid #ccc;
}

.csl-bib-body {
    margin-left: 1cm;
    text-indent: -1cm;
    display: inline-block;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    width: calc(100% - 1cm);
    word-break: break-word;
}

.csl-entry {
  margin-left: 4em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
/*
Times New Roman", Tinos, "Liberation Serif", serif
*/
/* Document userprofile style */
.userProfile:focus{
outline: none !important;
}