@media only screen and (max-width: 600px) {
  /* .MuiList-root {
    padding-top: 40px !important;
  } */
}

#menuList {
  padding-top: 15px !important;
  height:170%;
}

.makeStyles-drawerOpen-10 {
  width: 250px !important;
}
/* 
.MuiList-padding {
  padding-top: 45px !important;
} */
.corner-top-navbar{
  background-color: #1976d2;

}

.dark-mode .corner-top-navbar{
  background-color: black;
}

/* .dark-mode .css-i4bv87-MuiSvgIcon-root{
  color: white;
} */

.text-custom {
  color: #3d83fa;
}

.dark-mode .text-custom {
  color: white;
}