.dark-mode .top-bar-main {
    background-color: #323232 !important;
}

.dark-mode .top-bar22 {
    background-color: transparent;
}

.pb-3 {
    background-color: white;
}

.dark-mode .pb-3 {
    background-color: #323232;
}

.dark-mode .tones-heading {
    color: white;
}

.dark-mode .paraphrase_moveup__3EXuF {
    background-color: #151515;
}

.custom-heading {
    color: #303030
}

.dark-mode .custom-heading {
    color: #d7d7d7
}

.dark-mode .jodit-wysiwyg {
    background-color: #323232 !important;
}

.diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}

.dark-mode .diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}