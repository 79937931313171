.dark-mode .css-jfggi0 {
    background-color: transparent;
    border: 1px solid white;

  }

.dark-mode .bg-white {
    background-color: transparent;
  }

  .diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}

.dark-mode .diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}