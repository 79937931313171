.background-red {
  /* background-color: #bbdefb; */
  background-color: #ffffff;
  /* border-left: 5px solid #386cfc; */
  /* color: #386cfc; */
  color: #1976D2;
  border-start-end-radius: 1rem;
  border-end-end-radius: 1rem;
  box-shadow: 3px 6px 7px 0px #363636;
}

.background-red2 {
  /* background-color: #bbdefb; */
  background-color: #ffffff;
  /* border-left: 5px solid #386cfc; */
  /* color: #386cfc; */
  color: #1976D2;
  border-radius: 1rem;
  /* border-start-end-radius: 1rem;
  border-end-end-radius: 1rem; */
  box-shadow: 4px 8px 16px 1px #000000;
}

.background-grey {
  background-color: #ccced1 !important;
}

.iconCol {
  /* color:"black !important" */
  color: #1976d2 !important;
}

/* 
.sidenavbar-textlink {
  text-decoration: none;
  color: #ffffff;
} */

#menuList {
  padding-top: 15px !important;
}


.dark-mode .MuiDivider-root{
  background-color: #ffffff;
}

.dark-mode .makeStyles-toolbarB-13 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: black  !important;
  border-top-right-radius: 50px !important;
}
.dark-mode .MuiAvatar-colorDefault{
  color: black;
  background-color: #1976d2;
}

/* Styles for the text tag */
.text-custom {
  color: #3d83fa;
}

/* Dark mode styling */
.dark-mode .text-custom {
  color: white;
}

.dark-mode li div.MuiListItemIcon-root {
  background-color: transparent !important;
}

.dark-mode .popover-body{
  background-color: rgb(214, 213, 213);
  border: #363636;
}

.notification-div{
  background-color: white;
}

.dark-mode .notification-div{
  background-color: rgb(214, 213, 213);
}
.corner-top-navbar{
  background-color: #1976d2;

}

.dark-mode .corner-top-navbar{
  background-color: black;
}