.receivermessage {
  align-self: flex-start;
  background: white;
  padding: 5px;
  max-width: 50%;
  min-width: 140px;
  width: fit-content;
  border-radius: 10px;
  border-bottom: 2px solid rgba(37, 37, 37, 0.075);
  margin-bottom: 4px;
}
.receivermessage1{
  align-self: flex-start;
  background: white;
  padding: 5px;
  max-width: 50%;
  min-width: 100%;
  /* min-width: 250px; */
  width: fit-content;
  border-radius: 10px;
  border-bottom: 2px solid rgba(37, 37, 37, 0.075);
  margin-bottom: 4px;
}
.receivermessage > p {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.receivermessage > span {
  float: right;
  padding: 0px 5px;
  font-size: 0.9em;
  color: #c9c7c7;
}
