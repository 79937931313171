.MuiPaper-root .MuiCard-root .makeStyles-root-299 .MuiPaper-elevation1 .MuiPaper-rounded{
    border: 0px !important;
}

.MuiTabs-flexContainer{
    background-color: white !important;
}

.card-bg{
    background-color: white;
}

.dark-mode .card-bg{
    background-color: #323232;
}

.diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}

.dark-mode .diabledInDarkMode:disabled {
    background-color: lightgray !important;
    color: gray !important;
    border-color: lightgray !important;
    /* cursor: not-allowed; */
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
   .head {
       margin-top: 2% !important;
   }

   .headtwo {
       margin-left: -2% !important;
   }
  }


.dark-mode .makeStyles-demo1-21{
    background-color: black;
}