.dark-mode .input-tag {
    background-color: #2a2a2a;
    border: 1px solid white;
}

.dark-mode .InputTagStyle {
    color: white !important;
    background-color: #2a2a2a !important;
}

.dark-mode .InputTagStyle::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
}