.chatinput {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.chatinput__form {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px;
}

.dark-mode .chatinput__form {
  background-color: #2a2a2a;
  color: white;
  border: 1px solid white;
}

.dark-mode .whiteText{
  color: white;
}

.chatinput__form .MuiSvgIcon-root {
  cursor: pointer;
  padding: 0px 5px;
  color: gray;
}
.chatinput__form > form {
  flex: 1;
}
.chatinput__form > form > input {
  background: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  width: 100%;
}
.chatinput__formIcons {
  display: flex;
  align-items: center;
}
.chatinput__icons {
  background: rgb(61, 131, 250);
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
}

.chatinput__icons > .MuiSvgIcon-root {
  cursor: pointer;
  padding: 10px;
  color: white;
}

.chatinput__form > .emoji-picker-react {
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.audio-recorder {
  width: 10ch !important;
  height: 15px !important;
}

.emoji-picker-container {
  position: absolute;
  left: 5%;
  top:9%;
  width: 200px;
  height: 200px;
  z-index: 1;
}