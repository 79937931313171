.add-new-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1220;
}

.popup {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 800px;
  height:630px;
  /* min-height: 400px; /* Ensures minimum height */
  /* max-height: 1000vh; Restrict the popup to 80% of the viewport height */ 
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky; /* Fixes the header at the top */
  top: 0;
  background-color: white; /* Ensure it doesn't overlap content */
  padding-bottom: 10px;
  z-index: 2;
  /* border-bottom: 1px solid #ddd; Optional: Adds a bottom border for separation */
  /* box-shadow: 5px 10px 15px lightgray; */
}

.popup-header h3 {
  margin: 0;
  color: #007bff;
}

.popup-header span {
  cursor: pointer;
}

.popup-content {
  overflow-y: auto; /* Makes the content scrollable */
  flex-grow: 1; /* Allow the content area to expand */
  margin-bottom: 20px; /* Ensure space for the bottom buttons */
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: sticky;
  bottom: 0;
  background-color: white; /* Ensure the buttons are not hidden by scroll */
  padding-top: 10px;
  z-index: 2;
  border-top: 1px solid #ddd; /* Optional: Adds a top border for separation */
}

.popup-buttons button {
  padding: 10px 20px;
  cursor: pointer;
}

.popup h3 {
  margin: 0 0 20px;
  color: #333;
}

.form-popup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row-popup {
  display: flex;
  justify-content:start;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  width:100%;
}
.form-row-popup input,
.form-row-popup select{
  width:30%;
  height:30px;
  border:1px solid #ccc;
}
.form-column{
  width:32%
}
form-popup input,
form-popup select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.permissions-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.permissions-section label {
  width: 32%;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
}
.buttons{
  display: flex;
  width:45%;
  justify-content: space-between;
}

.cancel-btn {
  background-color:white;
  color: '#ff4d4d';
  padding: 10px 15px;
  border: 1px solid #ff4d4d;
  border-radius: 5px;
  cursor: pointer;
  width:48%;
}

.save-btn {
  background-color: #1976D2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width:48%;
}
.add-btn {
  background-color: #1976D2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width:30%;
}
.permissions-section {
  display: flex;
  width:100%;
  justify-content: start;
  column-gap: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.permission-item {
  display: flex;
  width:32%;
  align-items: center; /* Align checkbox and label vertically */
}

.permission-item input[type="checkbox"] {
  /* margin-right: 10px; Space between checkbox and label */
  width:20%;
  justify-content: space-between;
}

.permission-item label {
  width:75%;
  margin-top: 8px;
  font-size: 14px;
  cursor: pointer; /* Pointer for a better user experience */
}
.accordion {
  background-color: #1976D2;
  color: white;
  cursor: pointer;
  padding: 10px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.accordion:hover {
  background-color: #0056b3;
}

.accordion:focus {
  outline: 2px solid #0056b3;
}

.panel {
  display: none;
  background-color: #f9f9f9;
  padding: 10px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.panel.active {
  display: block;
}
/* .billing-cycles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
} */

.billing-cycles-header h4 {
  margin: 0;
}

.billing-cycles-header button {
  padding: 8px 15px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #e2e2e2;
}

