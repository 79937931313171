.docImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#chip {
  height: 100% !important;
  margin-top: 1% !important;
}

@media only screen and (max-width: 768px) {
  .removeBorder {
    margin-top: 8% !important;
  }

  #chip {
    width: 25% !important;
    height: 55% !important;
  }

  .rateCol {
    /* margin-top: -11% !important; */
    margin-bottom: 1% !important;
  }
}

@media only screen and (max-width: 410px) {
  .rateCol {
    margin-top: -11% !important;
  }
}

@media (min-width: 411px) and (max-width: 575px) {
  .rateCol {
    margin-top: -6% !important;
  }
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 80px;
}

#mainPreview {
  transition: margin-right .5s;
}

@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}

.accordion .macro-button:before {
  color: #fff;
  content: "\f0da";
  float: right; 
  padding-right: 10px;
}

.accordion .macro-button.collapsed:before {
  content: "\f0d7"; 
}