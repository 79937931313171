.faq-container {
  background-color: white;
  border-radius: 15px;
  width: 100%;
}

.faqpage {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 80%;
  overflow-x: auto;
}

.leftcontent {
  width: 50%;
}

.rightcontent {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.main-title {
  margin-bottom: 20px;
  font-weight: bold;
  color: #1976d2;
}


.faqCards {
  max-height: 500px; 
  overflow-y: auto;
  padding-right: 10px; 
}


.rightcontent {
  text-align: center;
  margin-left: 30px;
  margin-top: 20px;
}

.faqImage {
  width: 90%;
  height: auto;
  margin-bottom: 20px;
}

.questionBox {
  margin-top: 20px;
  text-align: center;
  width: 90%;
}

.questionTitle {
  font-size: 25px ;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0e58a1;
}

.questionSubtitle {
  margin-bottom: 20px;
  color: #1976d2;
}

.questionField {
  width: 100%;
  height: 30%;
}

.sendButton {
  background-color: #1976d2;
  color: #fff;
  height: 30px;
  border: none;
  border-radius: 5px;
}

.sendbtn{
  margin-top: 20px;
}

.noSearchDialogContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}

.noSearchDialogContent {
  width: 450px; 
  height: 320px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 16px; 
  border-radius: 8px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.noSearchImage {
  width: 150px; 
  height: 160px;
}

.noSearchTitle {
  color: #1976d2;
  font-weight: bolder !important;
  padding: 0 !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
}

.NosearchSubtitle {
  margin-top: 8px !important;
}

.noSearchDialogActions {
  margin-top: auto;
}


.dark-mode .faq-container{
  background-color: #2A2A2A;
}

.dark-mode .main-title,
.dark-mode .questionSubtitle,
.dark-mode .questionTitle {
  color: #fff;
}
.dark-mode .noSearchTitle{
  color: white;
  font-weight: bolder !important;
  padding: 0 !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
}
.dark-mode .noSearchDialogContent{
  background-color: #2A2A2A;
  width: 450px; 
  height: 320px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px; 
  border-radius: 8px;
}