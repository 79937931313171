.jodit-icon {
    fill: #3783cf !important;
}

.jodit-ui-group:last-child {
    background-color: white;
    border: none;
    border-radius: 10px !important;
}


.custom-editor {
    border: none !important;
    border-radius: 8px !important;
    padding: 2px;
    height: 60vh;
    background-color: white;
    box-shadow: 2px 2px 5px -2px #000000;
    /* Add more custom styles as needed */
}
.custom-editor.fullsize-mode {
    height: 100vh; /* Fullsize height set to 60vh */
}

.jodit-container{
    border: none !important;
}
/* 
.jodit-editor{
    max-height: 400px !important; Adjust the value as needed
    overflow-y: auto !important;
    min-height: 400px !important;
} */


.jodit_toolbar .jodit_toolbar_btn-select select {
    font-family: 'Arial', sans-serif;
    /* Customize font family */
    font-size: 14px;
    /* Customize font size */
    color: #333;
    /* Customize font color */
}

.jodit ol {
    /* Add your styles here */
    list-style-type: decimal;
    /* Change list-style-type as needed (e.g., decimal, lower-alpha, upper-roman, etc.) */
    margin-left: 20px;
    /* Adjust the left margin */
    padding-left: 20px;
    /* Adjust the left padding */
}

.jodit ul {
    /* Add your styles here */
    list-style-type: disc;
    /* Change list-style-type as needed (e.g., disc, circle, square) */
    margin-left: 20px;
    /* Adjust the left margin */
    padding-left: 20px;
    /* Adjust the left padding */
}

/* find and replace */
/* .jodit-find-popup .jodit-find-popup__prev {
    background-image: url('C:\Users\Vijayaragavesh\Documents\gitcode\POET_Frontend\src\static\Group 745.png') !important;
}

.jodit-find-popup .jodit-find-popup__next {
    background-image: url('/src/static/Group 746.png') !important;
}

.jodit-find-popup .jodit-find-popup__close {
    background-image: url('/src/static/close.png') !important;
} */

.jodit-ui-search__box{
    background-color: #f9f9f9;
    border: solid #dadada;
    border-width: 0 0 1px 1px;
    display: flex;
    padding: calc(8px/2);
    position: absolute;
    right: 0;
    width: 350px !important;
}

.jodit-ui-search__inputs{
    width: 38% !important;
    height: auto !important;
}

/* .jodit-ui-search__buttons{
    height: auto !important;
} */

.jodit-wysiwyg p {
    margin: 0em !important;
}
