.MuiCardContent-root {
  max-width: 100% !important;
  max-height: 100% !important;
}

/* .MuiCardActions-root {
  display: none; 
} */

.dark-mode .grayBG {
  background-color: #2a2a2a;
  color: white;
}

.cardStyle {
  border: 1px solid white !important;
  box-shadow: 3px 1px 8px 0px rgba(0,0,0,0.25) !important;
}

.dark-mode .cardStyle {
  border: 1px solid black !important;
  box-shadow: none !important;
}