
  .bar1{
    font-family: "FontAwesome", sans-serif;
    border: 1px solid #a8a8a8;
    width: 100%;
     height:'52px';
     margin-bottom: 10px;

  }
  .date-picker {
    width: 100%;
    margin-right: 8px;
  }
  
  .reset-button {
    margin-top: 8px;
  }

  .dark-mode .OutlinedDarkBtn {
    color: white !important;
    border: 1px solid white !important;
}
  
  /* Mobile View Styles */
  .responsive-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  
  .search-bar-container {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .filters-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  

  
  .subtab-container {
    width: 100%;
  }
  
  /* Desktop View Styles */
  .desktop-container .main-row {
    background-color: red;
    display: flex;
    flex-wrap: wrap;
  }
  
  .desktop-container .subtab-section {
    background-color: yellow;
  }
  
  .desktop-container .search-filters-section {
    display: flex;
    align-items: center;
  }
  
  /* Additional Styling for Elements */
  .sepratorMargin {
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
  }

  .reset_button{
    margin-left: 10px;
  }


  .responsive-date-picker{
    margin-right: 10px;
  }

  .inputThemeStyle{
    background-color: white;
    border: 1px solid #a8a8a8;
    color: black
  }

  .dark-mode .inputThemeStyle {
    background-color: #2a2a2a;
    border: 1px solid #2a2a2a;
    color: white
  }

  /* DatePickerStyles.css */

/* Style for the TextField of the DatePicker */
.date-picker-input {
  width: 25%;
  margin-right: 8px;
}

/* DatePickerStyles.css */

/* Style for the TextField of the DatePicker */
.date-picker-input {
  width: 25%;
  margin-right: 8px;
}

/* Dark mode styles */
.dark-mode .MuiOutlinedInput-root {
  background-color: #2a2a2a !important; /* Input background color */
  color: lightgray !important; /* Input text color */
}




  @media (max-width: 750px) and (min-width: 551px) {
 
  
    .search-bar-container {
      width: 100%;
      margin-bottom: 16px;
    }
  
    .filters-container {
      display: flex;
      align-items: center;
      width: 100%;
    }
  
    .subtab-container {
      width: 100%;
      margin-top: 16px;
    }
  
    .date-picker {
      width: 25%;
      margin-right: 8px;
    }
  
    .reset-button {
      margin-left: 8px;
    }
    .separatorMargin{
        margin: 0px 5px;
    }

    .responsive-container {
        padding: 5px;
      }

  }

  @media (max-width: 550px) {
    .responsive-date-picker {
      padding: 4px;
      font-size: 12px;
      border-radius: 8px; /* Adjust the border-radius as needed */
    }
  
    .responsive-reset-button {
      padding: 4px 8px;
      font-size: 12px;
      border-radius: 8px; /* Adjust the border-radius as needed */
    }
  
    .filters-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
       /* Adjust the gap between items */
    }
  
    .separatorMargin {
      display: none;
    }
    
    .responsive-container {

       padding-left: 0px;
      }


      .search-bar-container {
        margin-bottom: 10px;
      }

      .responsive-reset-button{
        width: 100%;
        padding: 10px;
      }
      .reset_button{
        margin-left: 0px;
      }
    
    
    }

    @media (max-width: 450px) and (min-width: 300px) {
      
      .responsive-container {

        padding: 0px;
       }
 
    }