.Pbody {

  width: 100%;
  height: 100vh;

}



.Pcontainer {
  position: absolute;
  top: 34%;
  left: 24%;
  background-color: white;
  height: 375px;
  width: 700px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 


.PtextArea {
  position: absolute;
  height: 280px;
  width: 665px;
  background-color: white;
  border: 1px solid transparent;
  outline: none;
  border-radius: 10px;
  padding: 15px;
  resize: none;
  font-size: 15px;
  z-index: auto;
}

.editortext {
  padding-right: 5%;
  max-height: 55vh;
  width: 104vh;
  overflow-y: auto;
  border-radius: 8px !important;
  padding-bottom: 100px;
  box-shadow: 2px 2px 5px -2px #000000;
}

.textarea:focus {
  outline: none;
}



.Pmain {
  background-color: white;
  position: relative;
  left: 0;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.Pbtn {

  width: 50%;


}

#PmainBtn1 {
  margin: 10px;
  color: grey;
  height: 40px;

}

#PmainBtn2 {
  margin: 10px;
  width: 150px;
  height: 40px;
  font-size: 12px;

}

.Picons {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 10px;
  position: absolute;
  left: 54%;
  top: 1%;
}


.PiconDiv{
  height: 45px;
  width: 40px;
  border: 1px solid skyblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:5px;
}





.Pli {
  position: absolute;
  top: 95%;
  left: 25%;
}

#Pdlt {
  color: red;
  
}

.iconDiv:hover {
  border: 1px solid skyblue;
}


.countCardheader {
  font-weight: 500;
  font-size: 16px;
  color: #1976D2;
  text-align: center;
}

.countCardValues{
  font-weight:400 ;
  font-size: 14px;
  color: #000000;
}
.sentencewiseCard{
  min-height: 45vh;
  max-height: 45vh;
  overflow-y: auto !important;
  box-shadow: 2px 2px 5px -2px #000000;
}

.progressText{
  font-size: 14px;
  font-weight: 600;
}

.iconButton{
  outline:none;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid #1976D2;
  height: 50px;
  width: 50px;
}

.top-bar{
  background-color: white;
}

.dark-mode .top-bar{
  background-color: #2a2a2a;
}

.dark-mode .zzz{
  color: white !important;
}

.dark-mode .MuiDataGrid-cellContent {
  color: white !important
}

.senen{
  background-color: #CFE7FF;
}

.dark-mode .senen{
  background-color: #646464;
}

.dark-mode .editor{
  background-color: #323232;
  color: white;
}