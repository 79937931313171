.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.25;
  flex-basis: 25%;
  background-color: #fff;
}

.contacts__heading {
  text-align: center;
  background: rgb(61, 131, 250);
  color: white;
}
.contacts__heading > h1 {
  font-weight: 545;
}

.contacts__list {
  flex: 1;
  overflow-y: auto;
}

.dark-mode .contacts__list{
  background-color: #2a2a2a;
}

.MuiPaper-elevation{
  border: none !important;
  box-shadow: none !important;
}

.dark-mode .graybg {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .darkStyle{
  background-color: black;
  color: white;
}