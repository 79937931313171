.FilledDarkBtn {
    background-color: rgb(63, 81, 181) !important;
    color: white !important;
    border: rgb(63, 81, 181) !important;
}

.dark-mode .FilledDarkBtn {
    /* background-color: rgb(63, 81, 181) !important;
    color: white !important;
    border: rgb(63, 81, 181) !important; */
    color: white !important;
    border: 1px solid white !important;
    background-color: transparent !important;
}

.OutlinedDarkBtn2 {
    color: #1976D2 !important;
    border: 1px solid #1976D2 !important;
}

.OutlinedDarkBtn2:disabled {
    background-color: lightgray !important; /* Set background to gray when disabled */
    color: gray !important; /* Optional: make text lighter for a disabled effect */
    border-color: lightgray !important; /* Optional: change the border color for a disabled effect */
    cursor: not-allowed;
}

.dark-mode .OutlinedDarkBtn2:disabled {
    background-color: lightgray !important; /* Set background to gray when disabled */
    color: gray !important; /* Optional: make text lighter for a disabled effect */
    border-color: lightgray !important; /* Optional: change the border color for a disabled effect */
    cursor: not-allowed;
}

.dark-mode .OutlinedDarkBtn2 {
    color: white !important;
    border: 1px solid white !important;
}

.changeBG {
    background-color: white !important;
}

.dark-mode .changeBG {
    background-color: #2a2a2a !important;
}
.dark-mode .btnTextColor {
    color: lightgray !important;
}

/* Dark mode styles for Jodit toolbar */
.dark-mode .jodit-toolbar-collection,
.dark-mode .jodit-toolbar-editor-collection {
    background-color: #2a2a2a !important;
    padding: 10px 0px;
    border-bottom: 2px solid lightgray;
}

/* Dark mode styles for jodit-ui-group */
.dark-mode .jodit-ui-group:last-child {
    background-color: white;
    width: 99% !important;   /* Set width to 95% */
    margin: auto !important; /* Center the element */
}

.dark-mode .jodit-toolbar-editor-collection_size_small.jodit-toolbar-editor-collection_mode_horizontal {
    background-image: repeating-linear-gradient(transparent 0, transparent 31px, #2a2a2a 32px);
}

/* Dark mode styles for Jodit Editor */
.dark-mode .jodit-wysiwyg {
    background-color: #2a2a2a !important; /* Set the background color to green */
    color: white !important; /* Default text color to white */
}

/* Optional: Set placeholder text color in dark mode */
.dark-mode .jodit-wysiwyg::placeholder {
    color: white !important; /* Placeholder text color */
}

.dark-mode .custom-editor {
    background-color: #2a2a2a;
    border-radius: 0px !important;
    box-shadow: none;
    border-bottom: 2px solid lightgray;
}

.dark-mode .borderTop{
    border-top: 3px solid lightgray;
}

.uploadcolor{
    color: indigo !important;
}

.dark-mode .uploadcolor{
    color: #CE68FF !important;
}

.uploadcolor.Mui-disabled{
    color: gray !important;
}

.translateColor {
    color: orange !important;
}

.translateColor.Mui-disabled{
    color: gray !important;
}