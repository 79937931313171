.dark-mode .zzz{
    color: white;
}

.dark-mode .modal-content{
    background-color: black;
    border: 1px solid white;
}

.dark-mode .ant-modal-content{
    background-color: black;
    border: 1px solid white;
}

.dark-mode .ant-modal-title{
    background-color: black;
    color: white;
}