.FilledDarkBtn {
    background-color: rgb(63, 81, 181) !important;
    color: white !important;
    border: rgb(63, 81, 181) !important;
}

.dark-mode .FilledDarkBtn {
    /* background-color: rgb(63, 81, 181) !important;
    color: white !important;
    border: rgb(63, 81, 181) !important; */
    color: white !important;
    border: 1px solid white !important;
    background-color: transparent !important;
}

.OutlinedDarkBtn {
    color: rgb(63, 81, 181) !important;
    border: 1px solid rgb(63, 81, 181) !important;
}

.dark-mode .OutlinedDarkBtn {
    color: white !important;
    border: 1px solid white !important;
}

/* CoAuthor.css */

/* Apply styles for InputField in dark mode */
.dark-mode .InputStyle {
    border: 1px solid white !important;
    /* Border color in dark mode */
}

/* Change label color in dark mode */
.dark-mode .InputStyle .MuiInputLabel-root {
    color: white !important;
    /* White label text in dark mode */
}

/* Change input text color in dark mode */
.dark-mode .InputStyle .MuiOutlinedInput-input {
    color: white !important;
    /* Ensure typed text is white */
}

/* Set the background color for the input field in dark mode */
.dark-mode .InputStyle .MuiOutlinedInput-root {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* Dark background */
}

/* Change placeholder color in dark mode */
.dark-mode .InputStyle .MuiInputBase-input::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
    /* Light gray for placeholder */
}

/* Change border color in dark mode */
.dark-mode .InputStyle .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    /* Border outline color */
}

/* Additional specificity for the input color */
.dark-mode .InputStyle .MuiInputBase-input {
    color: white !important;
    /* Explicitly set the input color */
}

.dark-mode .headingh2 {
    color: white !important;
}



/* Change checkbox color in dark mode */
.dark-mode .MuiCheckbox-root {
    color: white !important;
    /* Checkbox color in dark mode */
}

/* Change the label color in dark mode */
.dark-mode .MuiFormControlLabel-label {
    color: white !important;
    /* Label text color */
}

/* Optional: Style for the FormControl itself, if needed */
/* .dark-mode .MuiFormControl-root {
    background-color: rgba(0, 0, 0, 0.8) !important;
} */

.dark-mode .headingh2 {
    color: white;
}

.dark-mode hr {
    border: 1px solid white;
}


/* Pagination container in dark mode */
.dark-mode .MuiPagination-root {
    color: white !important;
    /* Ensures the pagination numbers and arrows are white */
}

/* Pagination buttons in dark mode */
.dark-mode .MuiPaginationItem-root {
    color: white !important;
    /* Text color for pagination buttons */
}

/* Pagination active button in dark mode */
.dark-mode .Mui-selected {
    /* background-color: #007bff !important;  */
    color: white !important;
    /* Text color for the selected page */
    border: 1px solid white;
    background-color: transparent !important;
}

.dark-mode .MuiPaginationItem-root.Mui-selected {
    color: white !important;
}

/* Pagination arrows in dark mode */
.dark-mode .MuiPaginationItem-ellipsis,
.dark-mode .MuiPaginationItem-icon {
    color: white !important;
    /* Arrow color */
}


/* Styles for contained button in dark mode */
.dark-mode .MuiButton-contained {
    color: white !important;
    /* Text color for contained button */
    background-color: rgb(63, 81, 181) !important;
    /* Background color for contained button */
    border: none !important;
    /* No border for contained button */
}

/* Hover styles for contained button */
.dark-mode .MuiButton-contained:hover {
    background-color: rgba(63, 81, 181, 0.8) !important;
    /* Slightly darker background on hover */
    color: white !important;
    /* Ensure text remains white on hover */
}

/* Styles for outlined button in dark mode */
.dark-mode .MuiButton-outlined {
    border-color: rgb(63, 81, 181) !important;
    /* Border color for outlined button */
    background-color: transparent !important;
    /* Transparent background for outlined button */
    color: rgb(63, 81, 181) !important;
    /* Text color for outlined button */
}

/* Hover styles for outlined button */
.dark-mode .MuiButton-outlined:hover {
    background-color: rgba(63, 81, 181, 0.1) !important;
    /* Light background on hover */
    color: rgb(63, 81, 181) !important;
    /* Maintain text color on hover */
}

.useTemplateModal {
    background-color: white !important;
    padding: 40px;
    border-radius: 8px;
    max-width: 70%;
    max-height: 580px;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
}

.dark-mode .useTemplateModal {
    background-color: black !important;
    border: 1px solid white !important;
}

.useTemplateModalIcon {
    color: red !important;
}

.dark-mode .useTemplateModalIcon {
    color: red !important;
}

.boxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 75vh;
    background-color: white;
    border: 2px solid black;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.dark-mode .boxStyle {
    border: 2px solid white;
    background-color: black;
}

.innerboxStyle{
    background-color: white;
    /* padding: 30px;  */
    height: 78%; 
    width: 90%;
    margin: auto;
    overflow-y: 'auto';
    border: 2px solid black;
}
.dark-mode .innerboxStyle {
    background-color: #2a2a2a;
    border: 2px solid white;
}

.changeBg{
    background-color: white;
}

.dark-mode .changeBg {
    background-color: black;
}

.diabledInDarkMode:disabled {
    background-color: lightgray !important; 
    color: gray !important; 
    border-color: lightgray !important; 
    /* cursor: not-allowed; */
  }
  
  .dark-mode .diabledInDarkMode:disabled {
    background-color: lightgray !important; 
    color: gray !important; 
    border-color: lightgray !important; 
    /* cursor: not-allowed; */
  }