@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card {
    width: 100%;
  }

  .search {
    width: 99% !important;
  }
  .searchBar {
    width: 100% !important;
    margin-top: 2% !important;
  }
  .grp_mem_searchBar {
    margin-right: 1px;
    padding-left: 20%;
  }
  .createGroup {
    margin-right: 20% !important;
    margin-top: 4% !important;
    width: 60% !important;
  }

  .inviteMember {
    margin-left: 20% !important;
    margin-top: 4% !important;
    margin-bottom: 5% !important;
    width: 60% !important;
  }
  .my_grp_details {
    padding-left: 30px;
  }
  .grp_mem_modal_text {
    font-weight: normal;
  }
  .av {
    width: 25% !important;
    height: 75% !important;
    border-radius: 50% !important;
  }

  .fa-trash {
    margin-top: 5% !important;
  }

  .teamHead {
    font-weight: bold;
    color: black !important;
  }
  .adminGrpDeleteBtn {
    margin-left: -40% !important;
  }
}

.setShadow {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .res-Group-card {
    flex: 50% !important;
    max-width: 50% !important;
  }
  .createGroup{
    width: 104px !important;
  }
}

@media only screen and (min-width: 830px) and (max-width: 1060px) {
  .res-Team-card {
    flex: 33.33% !important;
    max-width: 33.33% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 829px) {
  .res-Team-card {
    flex: 50% !important;
    max-width: 50% !important;
  }
}
#btncolor{
  background-color: #3f51b5;
}

.dark-mode #btncolor{
  background-color: #1976d2;
}

#btncolor:disabled{
  background-color: lightgray;
  color: gray;
}

#text-color{
  color: #3f51b5;
}
.dark-mode #text-color, .teamHead{
  color: white;
}
.dark-mode .custom-textfield {
  color: white;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root {
  border-color: white;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root.Mui-focused {
  border-color: white; 
}

.dark-mode .custom-textfield .MuiOutlinedInput-notchedOutline {
  border-color: white; 
}

.dark-mode .creategroup-text{
  color: aliceblue;
}

.dark-mode .groupname{
  background-color: black;
  color: white;
}

.dark-mode .react-multi-email{
  background:black;
  border: 1px solid white;
}

.dark-mode .react-multi-email > input{
  background-color:black;
  color: white;
}

.dark-mode .react-multi-email:focus {
  background-color: black !important; 
  color: white; 
  outline: none; 
}

.dark-mode .popover-teams .popover-body{
  background-color: black;
}

.group-modal{
  background-color: #f2f2f2;
  border: none;
}

.dark-mode .group-modal{
  background-color: black;
  color: white;
  border: 1px solid white;
}

.dark-mode .adminGrpUserName{
  color: white;
}

.dark-mode .close{
  color: red;
}