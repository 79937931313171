.editable{
    /* min-height: 105vh; */
    width: 100%;
    background-color: white;
    border-radius: 1px;
    border: none;
    padding-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 60px !important;
    padding-bottom: 50px !important;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
    overflow: hidden;
    display: block;
    resize: both;
  min-height: 300px;
}



.editabletwo{
    min-height: 105vh;
    width: 100%;
    background-color: white;
    border-radius: 1px;
    border: none;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 50px;
    padding-bottom: 50px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  /* border: 1px solid #888;  */
}


   
 .editable:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);
  }

  .btn_trns{
    /* background-color: none; */
    background-color: transparent;
    border: none;
    outline:none;
    /* width: 100%; */
}